@import '@mathison-inc/components/dist/main.css';

/* Remove this after App portal adapt the new design system */
@font-face {
  font-family: 'D-DIN';
  src: url('./assets/font/DINPro-Medium.woff2') format('woff2'),
    url('./assets/font/DINPro-Medium.woff') format('woff');
  font-weight: 500;
}

html,
body {
  height: 100%;
  background-color: #f7f7f7 !important;
}

body {
  margin: 0;
  font-family: 'IBM Plex Sans', 'Roboto', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body #main-root {
  min-height: 100%;
}

body #main-root a {
  text-decoration: none;
}
body #main-root a:-webkit-any-link {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skip-to-main-content-link {
  position: absolute;
  left: -9999px;
  z-index: 11999;
  padding: 1em;
  background-color: black;
  color: white;
  opacity: 0;
}
.skip-to-main-content-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
}
